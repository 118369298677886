import {
  Remove,
  Add,
  Delete,
  DeleteOutline,
  FileCopyOutlined,
  Edit,
  EditOutlined,
  Menu,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { CampaignState } from "../service/campaigns.types";
import { useJustification } from "modules/UI/hooks/useJustification.hook";
import { PUIContentCarousel } from "modules/UI/components/ContentCarousel.component";
import PUIRatioBox from "modules/UI/components/RatioBox.component";
import { UserFullScopeComponent } from "modules/User/components/userScopeSelector/userFullScopeComponent";
import Dropzone from "modules/Forms/components/dropzone.component";
import { FileRejection, DropEvent } from "react-dropzone";

export const CampaignMaterialCard = (props) => {
  const { material, campaign } = props;
  const [quantity, setQuantity] = useState(material.minimumOrderQuantity);
  const requestQuote = useJustification({
    title: `Ordering ${quantity} ${material.unitOfMeasure}`,
    description: "Your comments",
    submitButtonText: `Order ${quantity} ${material.unitOfMeasure}`,
    additionalContent: () => (
      <div>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Dropzone
              placeholder={
                "You can optionaly attach a file by dropping it here"
              }
              onDrop={function <T extends File>(
                acceptedFiles: T[],
                fileRejections: FileRejection[],
                event: DropEvent
              ): Promise<void> {
                throw new Error("Function not implemented.");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            Location
          </Grid>
          <Grid item xs={6}>
            <UserFullScopeComponent
              initialMarkets={[]}
              initialCompanies={[]}
              initialZones={[]}
              hasError={false}
              onChange={undefined}
            />
          </Grid>
        </Grid>
      </div>
    ),
  });
  return (
    <>
      {requestQuote.JSX}
      <Card sx={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          {material.mediaFiles.length > 0 ? (
            <PUIContentCarousel
              data={material.mediaFiles.map((file) => ({
                type: "image",
                thumbnail: file.url,
                src: file.url,
              }))}
              // onPreview={() => {}}
            />
          ) : (
            <PUIRatioBox xRatio={16} yRatio={9}>
              <></>
              {/* <Avatar
              variant="square"
              sx={{
                width: "100%",
                height: "100%",
                // background: "linear-gradient(112.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(157.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(135deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(90deg, rgb(195, 195, 195),rgb(228, 228, 228)); background-blend-mode:overlay,overlay,overlay,multiply",
                backgroundColor: promoCategory?.color || "#ccc",
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,1), rgba(255,255,255,.1))",
                backgroundBlendMode: "overlay",
                "& svg": {
                  opacity: 0.2,
                  width: "50%",
                  height: "50%",
                },
              }}
            >
              {promoCategory ? <promoCategory.icon /> : <></>}
            </Avatar> */}
            </PUIRatioBox>
          )}
        </Box>
        <CardContent>{material.shortDescription}</CardContent>
        <CardActionArea>
          {campaign.state === CampaignState.Draft && (
            <Box textAlign={"center"} sx={{ mb: 2 }}>
              <ButtonGroup variant="outlined" color="primary">
                <Button>
                  <DeleteOutline />
                </Button>
                <Button>
                  <FileCopyOutlined />
                </Button>
                <Button>
                  <EditOutlined />
                </Button>
              </ButtonGroup>
            </Box>
          )}
          {campaign.state === CampaignState.Active && (
            <Box textAlign={"center"}>
              <ButtonGroup variant="outlined" color="primary">
                <Button
                  disabled={quantity <= material.minimumOrderQuantity}
                  onClick={() => {
                    setQuantity((prev) => {
                      const newQuantity =
                        prev - material.purchQuantity * material.packQuantity;
                      return newQuantity;
                    });
                  }}
                >
                  <Remove />
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => {
                    requestQuote
                      .requestJustification()
                      .then(() => {
                        setQuantity(material.minimumOrderQuantity);
                        toast.success("Quote requested successfully!");
                      })
                      .catch(() => {
                        setQuantity(material.minimumOrderQuantity);
                        toast.error("Quote request canceled");
                      });
                  }}
                >
                  Order {quantity} {material.unitOfMeasure}
                </Button>
                <Button
                  onClick={() => {
                    setQuantity((prev) => {
                      const newQuantity =
                        prev + material.purchQuantity * material.packQuantity;
                      return newQuantity;
                    });
                  }}
                >
                  <Add />
                </Button>
              </ButtonGroup>
              <FormHelperText sx={{ textAlign: "center" }}>
                est. cost{" "}
                {quantity *
                  [...material.priceRanges]
                    .sort((a, b) => {
                      return a.threshold < b.threshold ? 1 : -1;
                    })
                    .find((r) => quantity >= r.threshold)?.unitPrice}{" "}
                {material.currencyCode}
              </FormHelperText>
            </Box>
          )}
        </CardActionArea>
      </Card>
    </>
  );
};
