import { Add, Campaign, Create, Share, Visibility } from "@mui/icons-material";
import { Box, Container, Fab, Grid, Typography } from "@mui/material";
import { Slide } from "modules/Layout/views/landing.view";
import Drawer from "modules/UI/components/Drawer.component";
import PUIRatioBox from "modules/UI/components/RatioBox.component";
import React, { useEffect, useRef } from "react";
import CreateCampaignContainer from "./create.container";
import { useQuery } from "@tanstack/react-query";
import CampaignsService from "../service/campaigns.service";
import { useNavigate } from "react-router-dom";
import CampaignCard from "modules/Campaigns/components/CampaignCard.component";
import { CampaignState } from "../service/campaigns.types";
import { PUIContentCarousel } from "modules/UI/components/ContentCarousel.component";
import { MaterialReactTable } from "material-react-table";
import CampaignsMaintenanceContainer from "./maintenance.container";

export const CampaignsListContainer = () => {
  const navigate = useNavigate();
  const activeCampaignsRef = useRef(null);
  const futureCampaignsRef = useRef(null);
  const pastCampaignsRef = useRef(null);
  const draftCampaignsRef = useRef(null);
  const archivedCampaignsRef = useRef(null);
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

  const { data: activeCampaigns, refetch: refetchActiveCampaigns } = useQuery({
    queryKey: ["campaigns/active"],
    queryFn: async () => {
      const response = await CampaignsService.getCampaigns({
        searchTerm: "",
        skip: 0,
        take: 100,
        state: CampaignState.Active,
      });
      return response;
    },
    initialData: { data: [], take: 100, skip: 0, total: 0 },
  });

  const { data: futureCampaigns, refetch: refetchFutureCampaigns } = useQuery({
    queryKey: ["campaigns/future"],
    queryFn: async () => {
      const response = await CampaignsService.getCampaigns({
        searchTerm: "",
        skip: 0,
        take: 100,
        state: CampaignState.Future,
      });
      return response;
    },
    initialData: { data: [], take: 100, skip: 0, total: 0 },
  });

  const { data: pastCampaigns, refetch: refetchPastCampaigns } = useQuery({
    queryKey: ["campaigns/past"],
    queryFn: async () => {
      const response = await CampaignsService.getCampaigns({
        searchTerm: "",
        skip: 0,
        take: 100,
        state: CampaignState.Past,
      });
      return response;
    },
    initialData: { data: [], take: 100, skip: 0, total: 0 },
  });

  const { data: draftCampaigns, refetch: refetchDraftCampaigns } = useQuery({
    queryKey: ["campaigns/draft"],
    queryFn: async () => {
      const response = await CampaignsService.getCampaigns({
        searchTerm: "",
        skip: 0,
        take: 100,
        state: CampaignState.Draft,
      });
      return response;
    },
    initialData: { data: [], take: 100, skip: 0, total: 0 },
  });

  const { data: archivedCampaigns, refetch: refetchArchivedCampaigns } =
    useQuery({
      queryKey: ["campaigns/archived"],
      queryFn: async () => {
        const response = await CampaignsService.getCampaigns({
          searchTerm: "",
          skip: 0,
          take: 100,
          state: CampaignState.Archived,
        });
        return response;
      },
      initialData: { data: [], take: 100, skip: 0, total: 0 },
    });

  useEffect(() => {
    [
      activeCampaignsRef,
      futureCampaignsRef,
      pastCampaignsRef,
      draftCampaignsRef,
      archivedCampaignsRef,
    ].forEach((ref) => {
      const slider = ref.current;
      let mouseDown = false;
      let startX, scrollLeft;

      const startDragging = (e) => {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      };

      const stopDragging = (e) => {
        mouseDown = false;
      };

      const move = (e) => {
        e.preventDefault();
        if (!mouseDown) {
          return;
        }
        const x = e.pageX - slider.offsetLeft;
        const scroll = x - startX;
        slider.scrollLeft = scrollLeft - scroll;
      };

      // Add the event listeners
      slider.addEventListener("mousemove", move, false);
      slider.addEventListener("mousedown", startDragging, false);
      slider.addEventListener("mouseup", stopDragging, false);
      slider.addEventListener("mouseleave", stopDragging, false);
    });
    // return () => {
    //   [
    //     activeCampaignsRef,
    //     futureCampaignsRef,
    //     pastCampaignsRef,
    //     draftCampaignsRef,
    //     archivedCampaignsRef,
    //   ].forEach((ref) => {
    //     const slider = ref.current;
    //     // Clean up the event listeners
    //     slider.removeEventListener("mousemove", move, false);
    //     slider.removeEventListener("mousedown", startDragging, false);
    //     slider.removeEventListener("mouseup", stopDragging, false);
    //     slider.removeEventListener("mouseleave", stopDragging, false);
    //   });
    // };
  }, []);

  return (
    <>
      <Drawer
        title="Create Campaign"
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      >
        {createDialogOpen && (
          <CreateCampaignContainer onClose={() => setCreateDialogOpen(false)} />
        )}
      </Drawer>
      <Box sx={{ m: -3, mb: 3 }}>
        <PUIContentCarousel
          data={activeCampaigns?.data
            ?.sort(function (a, b) {
              return (
                new Date(a.created).getTime() - new Date(b.created).getTime()
              );
            })
            .map((campaign) => ({
              campaignId: campaign.id,
              type: "image",
              thumbnail: campaign.mediaFiles
                .filter((file) => file.mimeType.indexOf("image/") > -1)
                .sort(function (a, b) {
                  return (
                    new Date(a.uploadedOn).getTime() -
                    new Date(b.uploadedOn).getTime()
                  );
                })[0]?.url,
              src: campaign.mediaFiles
                .filter((file) => file.mimeType.indexOf("image/") > -1)
                .sort(function (a, b) {
                  return (
                    new Date(a.uploadedOn).getTime() -
                    new Date(b.uploadedOn).getTime()
                  );
                })[0]?.url,
            }))}
          onPreview={(slide) => {
            navigate(`/campaigns/${slide.campaignId}`);
          }}
        />
        {/* <PUIRatioBox xRatio={16} yRatio={9}>
          <Slide
            isActive={activeSlide === 0}
            backgroundImage="https://i.ytimg.com/vi/5n4J9duypeQ/maxresdefault.jpg"
            onNext={() => {
              setActiveSlide(1);
            }}
          />
          <Slide
            isActive={activeSlide === 1}
            backgroundImage="https://mir-s3-cdn-cf.behance.net/project_modules/fs/de70ae11079143.560f10407d139.jpg"
            onPrevious={() => {
              setActiveSlide(0);
            }}
          />
        </PUIRatioBox> */}
      </Box>
      <Fab
        color="primary"
        variant="extended"
        sx={{ position: "fixed", bottom: 50, right: 50 }}
        onClick={() => setCreateDialogOpen(true)}
      >
        <Campaign sx={{ mr: 1 }} />
        New Campaign
      </Fab>

      <Container maxWidth="xl">
        <Grid container spacing={2}>
          {activeCampaigns?.data?.length > 0 && (
            <Grid item xs={12} mt={6}>
              <Typography variant="h2">Active Campaigns</Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            display="flex"
            overflow={"auto"}
            gap={3}
            sx={{
              "&::-webkit-scrollbar": {
                width: 0,
              },
            }}
            ref={activeCampaignsRef}
          >
            {activeCampaigns?.data
              ?.sort(function (a, b) {
                return (
                  new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
                );
              })
              .map((campaign) => (
                <CampaignCard
                  onRefetch={() => refetchActiveCampaigns()}
                  key={campaign.id}
                  {...campaign}
                  imageUrl={
                    campaign.mediaFiles
                      .filter((file) => file.mimeType.indexOf("image/") > -1)
                      .sort(function (a, b) {
                        return (
                          new Date(a.uploadedOn).getTime() -
                          new Date(b.uploadedOn).getTime()
                        );
                      })[0]?.url
                  }
                />
              ))}
          </Grid>
          {futureCampaigns?.data?.length > 0 && (
            <Grid item xs={12} mt={6}>
              <Typography variant="h2">Upcoming Campaigns</Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            display="flex"
            overflow={"auto"}
            gap={3}
            sx={{
              "&::-webkit-scrollbar": {
                width: 0,
              },
            }}
            ref={futureCampaignsRef}
          >
            {futureCampaigns?.data?.map((campaign) => (
              <CampaignCard
                onRefetch={() => refetchFutureCampaigns()}
                key={campaign.id}
                {...campaign}
                imageUrl={
                  campaign.mediaFiles
                    .filter((file) => file.mimeType.indexOf("image/") > -1)
                    .sort(function (a, b) {
                      return (
                        new Date(a.uploadedOn).getTime() -
                        new Date(b.uploadedOn).getTime()
                      );
                    })[0]?.url
                }
              />
            ))}
          </Grid>
          {pastCampaigns?.data?.length > 0 && (
            <Grid item xs={12} mt={6}>
              <Typography variant="h2">Past Campaigns</Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            display="flex"
            overflow={"auto"}
            gap={3}
            sx={{
              "&::-webkit-scrollbar": {
                width: 0,
              },
            }}
            ref={pastCampaignsRef}
          >
            {pastCampaigns?.data?.map((campaign) => (
              <CampaignCard
                onRefetch={() => refetchPastCampaigns()}
                key={campaign.id}
                {...campaign}
                imageUrl={
                  campaign.mediaFiles
                    .filter((file) => file.mimeType.indexOf("image/") > -1)
                    .sort(function (a, b) {
                      return (
                        new Date(a.uploadedOn).getTime() -
                        new Date(b.uploadedOn).getTime()
                      );
                    })[0]?.url
                }
              />
            ))}
          </Grid>
          {draftCampaigns?.data?.length > 0 && (
            <Grid item xs={12} mt={6}>
              <Typography variant="h2">Draft Campaigns</Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            display="flex"
            overflow={"auto"}
            gap={3}
            sx={{
              "&::-webkit-scrollbar": {
                width: 0,
              },
            }}
            ref={draftCampaignsRef}
          >
            {[...(draftCampaigns?.data || [])]
              .sort(function (a, b) {
                return (
                  new Date(b.modified).getTime() -
                  new Date(a.modified).getTime()
                );
              })
              .map((campaign) => (
                <CampaignCard
                  onRefetch={() => refetchDraftCampaigns()}
                  key={campaign.id}
                  {...campaign}
                  imageUrl={
                    campaign.mediaFiles
                      .filter((file) => file.mimeType.indexOf("image/") > -1)
                      .sort(function (a, b) {
                        return (
                          new Date(a.uploadedOn).getTime() -
                          new Date(b.uploadedOn).getTime()
                        );
                      })[0]?.url
                  }
                />
              ))}
          </Grid>
          {archivedCampaigns?.data?.length > 0 && (
            <Grid item xs={12} mt={6}>
              <Typography variant="h2">Archived Campaigns</Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            display="flex"
            overflow={"auto"}
            gap={3}
            sx={{
              "&::-webkit-scrollbar": {
                width: 0,
              },
            }}
            ref={archivedCampaignsRef}
          >
            {archivedCampaigns?.data?.map((campaign) => (
              <CampaignCard
                onRefetch={() => refetchArchivedCampaigns()}
                key={campaign.id}
                {...campaign}
                imageUrl={
                  campaign.mediaFiles
                    .filter((file) => file.mimeType.indexOf("image/") > -1)
                    .sort(function (a, b) {
                      return (
                        new Date(a.uploadedOn).getTime() -
                        new Date(b.uploadedOn).getTime()
                      );
                    })[0]?.url
                }
              />
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CampaignsListContainer;
