import { PromoItemRowSchema } from "modules/PromoItems/services/promoItems.schemas";
import {
  IdNamePairSchema,
  IdTitlePairSchema,
  ShortUserSchema,
} from "service/generic.schemas";
import * as yup from "yup";

export const MediaFileSchema = yup.object().shape({
  fileId: yup.number().nullable().notRequired(),
  url: yup.string().required(),
  mimeType: yup.string().required(),
  originalFileName: yup.string().required(),
  persistedFileNameInAzureStorage: yup.string().required(),
  uploadedOn: yup.string().required(),
  uploadedBy: ShortUserSchema,
});

export const CreateCampaignSchema = yup.object().shape({
  id: yup.number().nullable().notRequired(),
  name: yup.string().required("This field is required"),
  description: yup.string().required("This field is required"),
  startDate: yup
    .string()
    .datetime("Enter valid date")
    .required("This field is required"),
  endDate: yup
    .string()
    .datetime("Enter valid date")
    .required("This field is required"),
  supplierCode: yup.string().required("This field is required"),
  supplierName: yup.string().required("This field is required"),
  supplierContact: ShortUserSchema,
  zones: yup.array().of(IdTitlePairSchema),
  markets: yup.array().of(IdTitlePairSchema),
  companyCodes: yup.array().of(IdTitlePairSchema),
  mediaFiles: yup.array().of(MediaFileSchema),
});

export const CampaignMaterialSchema = PromoItemRowSchema.shape({
  // we're exending existing schema with new fields.
  id: yup.number().nullable().notRequired(),
  longDescription: yup.string().nullable().notRequired(),
  orderWindowId: yup.number().required("This field is required"),
  minimumOrderQuantity: yup.number().required("This field is required"),
  packQuantity: yup.number().required("This field is required"),
  packUnit: yup.string().required("This field is required"),
  purchQuantity: yup.number().required("This field is required"),
  incoterms: yup.string().required("This field is required"),
  priceRanges: yup.array().of(
    yup.object().shape({
      threshold: yup.number().required("This field is required"),
      unitPrice: yup.number().required("This field is required"),
    })
  ),
});
