import {
  createQueryService,
  createMutationService,
  getQueryStringWithoutUndefined,
  createBlobService,
  createFormDataService,
} from "utils/factories";
import ODataClientWrapper from "lib/odataClient.wrapper";
import { IHistoryData } from "../domains/pslHistory.domain";
import { PaginatedQueryResult } from "service/generic.types";

class NetworkCurrencyDTO {
  __metadata: { id: string; type: string; uri: string };
  CurrencyKey: string;
  Description: string;
}

export class CurrencyData {
  constructor(public CurrencyKey: string, public Description: string) {}

  static FromBackend(data: NetworkCurrencyDTO): CurrencyData {
    return new CurrencyData(data.CurrencyKey, data.Description);
  }

  static toSelect(data: CurrencyData) {
    return {
      value: data.CurrencyKey,
      label: data.CurrencyKey,
    };
  }

  static toSimpleSelect() {
    return [
      { value: "USD", label: "USD" },
      { value: "EUR", label: "EUR" },
      { value: "CHF", label: "CHF" },
    ];
  }
}

export interface IdNamePair {
  id: number;
  name: string;
}

export default class PromoItemsService {
  static readonly approvePsl = createMutationService<
    any,
    { pslId: string | number }
  >(
    (params) =>
      `/api/HackathonPsl/ApprovePsl${
        !params ? "" : "?"
      }${getQueryStringWithoutUndefined(params)}`,
    "POST"
  );

  static readonly rejectPsls = createMutationService<
    any,
    { pslIds: (string | number)[]; comment: string }
  >(
    (params) =>
      `/api/HackathonPsl/RejectPsl${
        !params ? "" : "?"
      }${getQueryStringWithoutUndefined(params)}`,
    "POST"
  );

  static readonly deletePsl = createMutationService<
    any,
    { pslId: string | number }
  >(({ pslId }) => `/api/HackathonPsl/DeletePsl?pslId=${pslId}`, "POST");

  static readonly toggleFavoriteMaterial = createMutationService<
    never,
    { pslId: string | number }
  >(
    ({ pslId }) => `/api/HackathonPsl/AddOrRemovePslFavorites?pslId=${pslId}`,
    "POST"
  );

  static readonly massImportMaterials = createFormDataService<
    any,
    { file: File }
  >("/api/Psl/PostPslListFromExcel", "POST");
  //test

  static readonly deletePromoMedia = createMutationService<
    any,
    { fileId: string }
  >(({ fileId }) => `/api/promo-items/files/${fileId}`, "DELETE");

  static readonly uploadPromoMediaFromLink = createMutationService<
    any,
    { link: string; materialId?: string }
  >(
    ({ link, materialId }) =>
      `/api/promo-items/files/upload-file-from-link?link=${link}${
        materialId ? `&materialId=${materialId}` : ""
      }`,
    "POST"
  );
  static readonly uploadPromoMedia = createFormDataService<any, any>(
    ({ materialId }) =>
      `/api/promo-items/files/upload-file?${
        materialId ? `materialId=${materialId}` : ""
      }`,
    "POST"
  );

  static readonly exportMaterials = createBlobService<any, any>(
    (params) =>
      `/api/HackathonPsl/GetPslMaterialsAsExcelV2${
        !params ? "" : "?"
      }${getQueryStringWithoutUndefined({
        searchTerm: params.searchTerm,
        take: params.take,
        skip: params.skip,
      })}`,
    "POST"
  );
  static readonly getMaterials = createMutationService<
    PaginatedQueryResult<any>,
    any
  >(
    (params) =>
      `/api/HackathonPsl/GetPslMaterialsV2${
        !params ? "" : "?"
      }${getQueryStringWithoutUndefined({
        searchTerm: params.searchTerm,
        take: params.take,
        skip: params.skip,
      })}`,
    "POST"
  );
  static readonly getMaterialsTemplate = createBlobService<any, any>(
    "/api/HackathonPsl/GetMaterialListExcelTemplate",
    "GET"
  );
  static readonly getPSLTemplate = createBlobService<any, any>(
    "/api/Psl/GetPslListExcelTemplate",
    "GET"
  );
  static readonly getPromoEntries = createMutationService<
    PaginatedQueryResult<any>,
    any
  >(
    (params) =>
      `/api/HackathonPsl/GetPslEntriesV2${
        !params ? "" : "?"
      }${getQueryStringWithoutUndefined({
        searchTerm: params.searchTerm,
        take: params.take,
        skip: params.skip,
      })}`,
    "POST"
  );

  static readonly addPslVendorsWithMaterials = createMutationService<any, any>(
    "/api/HackathonPsl/AddPSLVendorsWithMaterials",
    "POST"
  );
  static readonly localizePslVendorsWithMaterials = createMutationService<
    any,
    any
  >("/api/HackathonPsl/LocalizePslWithMaterials", "POST");

  static readonly updatePslVendorsWithMaterials = createMutationService<
    any,
    any
  >("/api/HackathonPsl/UpdatePSLVendorsWithMaterials", "POST");

  static readonly fetchCategoryTree = createQueryService(
    "/api/PslSpendCat/GetPslSpendCat"
  );

  static readonly fetchBusinessUnits = createQueryService<IdNamePair[]>(
    "/api/HackathonPsl/GetPslBusinessUnits"
  );
  static readonly getPslAmdrMaterials = createQueryService<
    any[],
    {
      searchTerm?: string;
      materialgroup?: string;
      locationCompanyCodes?: string;
      locationMarkets?: string;
      locationZones?: string;
      plants?: string;
    }
  >("/api/HackathonPsl/GetPslAmdrMaterials");

  static readonly getPslAmdrSuppliers = createQueryService<
    { id: string | number; name: string }[],
    {
      searchTerm?: string;
      locationCompanyCodes: string;
      isCreatePSL?: boolean;
    }
  >("/api/HackathonPsl/GetPslAmdrSuppliers");

  static readonly getPslAmdrSuppliersHierarchy = createQueryService<
    { id: string | number; name: string }[],
    {
      searchTerm?: string;
    }
  >("/api/HackathonPsl/GetPslAmdrSuppliersHierarchy");

  static readonly fetchUserEmails = createQueryService<
    string[],
    { searchTerm?: string }
  >("/api/CompetitiveBidding/ApproverEmails");

  static async fetchCurrencies(): Promise<CurrencyData[]> {
    return [
      { value: "USD", label: "USD" },
      { value: "EUR", label: "EUR" },
      { value: "CHF", label: "CHF" },
    ].map((c) => new CurrencyData(c.value, c.label));
    // const response = await ODataClientWrapper.get()
    //   .resource("CurrencySet")
    //   .execute();

    // return response?.data?.d?.results?.map(CurrencyData.FromBackend);
  }
  static readonly fetchLanguages = createQueryService<
    any,
    { searchTerm: string }
  >(`/api/HackathonPsl/GetLanguages`);

  static fetchPSLHistory = createQueryService<
    IHistoryData,
    { pslId: string | number }
  >(`/api/HackathonPsl/GetPslHistory`);

  static fetchMaterialHistory = createQueryService<
    IHistoryData,
    { materialId: string | number }
  >(`/api/HackathonPsl/GetPslHistory`);

  static readonly fetchPslEntry = createQueryService<any, { id: string }>(
    `/api/HackathonPsl/GetPslEntry`
  );
  static readonly fetchMaterialsForPslEntry = createQueryService<
    any,
    { id: string; includeDeleted?: boolean }
  >(`/api/HackathonPsl/GetMaterialsForPslEntry`);

  static async fetchPslInitialData({
    pslId,
  }): Promise<{ vendor: any; materials: any[] }> {
    const [vendor, materials] = await Promise.all([
      PromoItemsService.fetchPslEntry({ id: pslId }),
      PromoItemsService.fetchMaterialsForPslEntry({
        id: pslId,
        includeDeleted: false,
      }),
    ]);
    return { vendor, materials };
  }
  // Following is addapted from Contract Browser code. Here's the original for refference O.o:
  // static async fetchChildrenSuppliersV2(locationCompanyCodes: string = "", parentId: string, pslId?: number, zones: string = ""): Promise<ChildCodeResults> {
  //   const response = await AxiosWrapper.post('/api/HackathonPsl/GetPslAmdrSuppliersChildren',
  //     { locationCompanyCodes, childrenOf: parentId, parentId: pslId, zones }
  //   );
  //   return response.data;
  // }
  static readonly fetchChildrenSuppliersV2 = createMutationService<
    { results: any },
    {
      locationCompanyCodes: string;
      childrenOf: string;
      parentId?: number;
      zones: string;
    }
  >(`/api/HackathonPsl/GetPslAmdrSuppliersChildren`);

  static readonly fetchPslAmdrPlantsV2 = createQueryService<
    {
      code: string;
      name: string;
    }[],
    {
      text: string;
      locationZones: string;
      locationMarkets: string;
      locationCompanyCodes: string;
      materialNumber: string;
    }
  >(`/api/HackathonPsl/GetPslAmdrPlantsV2`);
}
