import {
  Card,
  CardContent,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import CheckboxWrapper from "modules/Forms/components/checkboxWrapper.component";
import { DatePickerWrapper } from "modules/Forms/components/datePickerWrapper.component";
import {
  CustomFastAsyncSelect,
  CustomFastSelect,
} from "modules/Forms/components/form.legacy.component";
import TextFieldWrapper from "modules/Forms/components/textFIeldWrapper.component";
import { UserScopeComponent } from "modules/User/components/userScopeSelector";
import React, { useEffect, useMemo } from "react";
import {
  PSL_AT_MARKET,
  PSL_ABOVE_MARKET,
  PSL_PPA_ABOVE_MARKET,
  PSL,
} from "../domains/psl.domain";
import PromoItemsService from "../services/promoItems.service";
import {
  SelectBlank,
  SelectMandatory,
  SelectSoleSource,
  SelectValidated,
  SupplierStatus,
} from "./create.container";
import { useQuery } from "@tanstack/react-query";
import { UserService, IUserScope } from "modules/User/service/user.service";
import ChildrenVendorComp from "../components/ChildrenVendorComp.legacy.component";
import { UserFullScopeComponent } from "modules/User/components/userScopeSelector/userFullScopeComponent";
import { useActiveUser } from "modules/User/activeUser";
import { is } from "ramda";
import { toast } from "react-toastify";
import {Supplier} from "../../User/service/user";

export const SUPPLIER_NOT_DEFINED_CODE = 999999999;

export const CreateStepOneContainer = () => {
  const activeUser = useActiveUser();
  const { values, setValues, setFieldValue, getFieldMeta, errors } = useFormikContext<any>();
  const [companyCodes, setCompanyCodes] = React.useState([]);
  const [forceChildCode, setForceChildCode] = React.useState(false);
  const [childrenVendor, setChildrenVendor] =
    React.useState<{ id: number; name: string; companyCodes: string }[]>(null);
  const [absentCompCodesAndAbsentMarkets, setAbsentCompCodesAndAbsentMarkets] =
    React.useState([]);
  const { data: userScope, isLoading: loadingUserScope } = useQuery({
    queryKey: ["userScope"],
    queryFn: () => UserService.fetchUserScope(false),
  });
  const { data: userFullScope, isLoading: loadingFullUserScope } = useQuery({
    queryKey: ["userFullScope"],
    queryFn: () => UserService.fetchUserFullScope(),
  });
  const { data: businessUnits, isLoading: loadingBusinessUnits } = useQuery({
    queryKey: ["businessUnits"],
    queryFn: () => PromoItemsService.fetchBusinessUnits(),
  });
  const { data: currencies, isLoading: loadingCurrencies } = useQuery({
    queryKey: ["currencies"],
    queryFn: () => PromoItemsService.fetchCurrencies(),
  });
  const locationCompanyCodes = useMemo(() => {
    return values.vendor.markets
      .map((market) => companyCodes[market.id])
      .concat(values.vendor.companyCodes.map((it) => it.code))
      .join(",");
  }, [companyCodes, values]);

  const pslClass = useMemo(() => {
    return new PSL(values.vendor);
  }, [values]);
  const isParentPsl =
    pslClass.isAboveMarket() ||
    (activeUser.hasAboveMarketRole() && values.vendor.pslId === null);
  const isSupplier = activeUser.isEditorSupplier()
  const firstChild = (activeUser.geChildrenSuppliers() && activeUser.geChildrenSuppliers()[0])??{supplierId: null, name: null}
  const firstParent = (activeUser.getParentSuppliers() && activeUser.getParentSuppliers()[0])??{supplierId: null, name: null}

  const isLocationEmpty = useMemo(() => {
    return (
      values.vendor.companyCodes?.length === 0 &&
      values.vendor.markets?.length === 0 &&
      values.vendor.zones?.length === 0
    );
  }, [values.vendor.companyCodes, values.vendor.markets, values.vendor.zones]);

  useEffect(() => {
    if (
      !isParentPsl ||
      !userFullScope ||
      loadingFullUserScope ||
      !!forceChildCode ||
      !values?.vendor?.childSupplierCode ||
      !(locationCompanyCodes || values.vendor.zones?.length > 0)
    )
      return;
    console.log({ values, userFullScope, cc: locationCompanyCodes });
    PromoItemsService.fetchChildrenSuppliersV2({
      locationCompanyCodes: locationCompanyCodes,
      childrenOf: values.vendor.childSupplierCode,
      parentId: values.vendor.pslId,
      zones: values.vendor.zones?.map((z) => z.id).join(","),
    })
      .then((response) => {
        setChildrenVendor(response.results);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, [
    locationCompanyCodes,
    userFullScope,
    values,
    forceChildCode,
    isParentPsl,
    loadingFullUserScope,
  ]);

  useEffect(() => {
    console.log("vendor", values.vendor);
    if (isSupplier) {
      if (values.vendor.childSupplierCode === null) {
        setFieldValue("vendor.childSupplierName", values.vendor?.value === 3 ? firstChild.name : firstParent.name);
        setFieldValue("vendor.childSupplierCode", values.vendor?.value === 3 ? firstChild.supplierId : firstParent.supplierId);
      }
    }
  }, [values.vendor]);
  useEffect(() => {
    if (values.vendor?.scope === 3) setForceChildCode(true);
    else setForceChildCode(false);
  }, [values.vendor?.scope]);
  useEffect(() => {
    if (userScope) {
      const all = (userScope as IUserScope[]).filter(
        (item) => item.fullMarketAccess || item.companyCodes.length > 0
      );
      let markets = [];
      all.forEach(function (full) {
        markets[full.market.id] = full.companyCodes?.map(
          (company) => company.code
        );
      });
      setCompanyCodes(markets);
    }
  }, [userScope]);

  return loadingBusinessUnits ||
    loadingCurrencies ||
    loadingUserScope ||
    loadingFullUserScope ? (
    <>loading...</>
  ) : (
    <Card>
      <CardContent>
        <Grid container spacing={4} alignItems={"flex-start"}>
          <Grid item xs={12} md={6}>
            <Typography sx={{ mt: 4, mb: 1 }} variant="h5">
              Buyer Details
            </Typography>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12} sm={4}>
                <FormLabel>Buyer Email*:</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <CustomFastAsyncSelect
                  // disabled={readOnly}
                  onLoad={async (searchTerm) => {
                    const result = await PromoItemsService.fetchUserEmails({
                      searchTerm,
                    });
                    return result.map((v) => ({
                      value: v,
                      label: v,
                    }));
                  }}
                  onLoadLimit={4}
                  initialValue={
                    values.vendor.strategicBuyerEmailAddress
                      ? {
                          value: values.vendor.strategicBuyerEmailAddress,
                          label: values.vendor.strategicBuyerEmailAddress,
                        }
                      : null
                  }
                  hasError={
                    !!getFieldMeta("vendor.strategicBuyerEmailAddress").error
                  }
                  onChange={(value) => {
                    setFieldValue(
                      "vendor.strategicBuyerEmailAddress",
                      value?.value
                    );
                  }}
                />
                {!!getFieldMeta("vendor.strategicBuyerEmailAddress").error && (
                  <FormHelperText error>
                    {getFieldMeta("vendor.strategicBuyerEmailAddress").error}
                  </FormHelperText>
                )}
              </Grid>
              {values.vendor.parentId > 0 &&
                values.vendor.scope === PSL_AT_MARKET && (
                  <>
                    <Grid item xs={12} sm={4}>
                      <FormLabel>Market Contact Name:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <CustomFastAsyncSelect
                        // disabled={readOnly}
                        onLoad={async (searchTerm) => {
                          const result =
                            await PromoItemsService.fetchUserEmails({
                              searchTerm,
                            });
                          return result.map((v) => ({
                            value: v,
                            label: v,
                          }));
                        }}
                        onLoadLimit={4}
                        initialValue={
                          values.marketContactName
                            ? {
                                value: values.vendor.marketContactName,
                                label: values.vendor.marketContactName,
                              }
                            : null
                        }
                        hasError={
                          !!getFieldMeta("vendor.marketContactName").error
                        }
                        onChange={(value) => {
                          setFieldValue(
                            "vendor.marketContactName",
                            value?.value
                          );
                        }}
                      />
                      {!!getFieldMeta("vendor.marketContactName").error && (
                        <FormHelperText error>
                          {getFieldMeta("vendor.marketContactName").error}
                        </FormHelperText>
                      )}
                    </Grid>
                  </>
                )}
              <Grid item xs={12} sm={4}>
                <FormLabel>Team Name*:</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextFieldWrapper
                  error={!!getFieldMeta("vendor.teamName").error}
                  name="vendor.teamName"
                  fullWidth
                  size="small"
                  helperText={getFieldMeta("vendor.teamName").error}
                />
              </Grid>
            </Grid>
            <Typography sx={{ mt: 4, mb: 1 }} variant="h5">
              Supplier Details
            </Typography>
            <Grid container spacing={2} alignItems={"flex-start"}>
              {isSupplier ? (
                  <>
                    <Grid item xs={12} sm={4}>
                      <CustomFastSelect
                          isMulti={false}
                          disabled={!!values.vendor.pslId}
                          clearable={false}
                          hasError={!!getFieldMeta("vendor.scope").error}
                          initialValue={
                            values.vendor.scope === 3
                                ? { value: 3, label: "Local Vendor" }
                                : { value: 1, label: "Parent Vendor" }
                          }
                          options={[
                            { value: 1, label: "Parent Vendor" },
                            { value: 3, label: "Local Vendor" },
                          ]}
                          onChange={(value) => {
                            setFieldValue("vendor.scope", value?.value);
                            setFieldValue("vendor.childSupplierName", value?.value === 3? firstChild.name : firstParent.name);
                            setFieldValue("vendor.childSupplierCode", value?.value === 3? firstChild.supplierId : firstParent.supplierId);
                          }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <CustomFastSelect
                          clearable={false}
                          disabled={!!values.vendor.pslId}
                          hasError={
                            !!getFieldMeta("vendor.childSupplierCode").error
                          }
                          options={
                            values.vendor.scope === 3 ?
                              activeUser.geChildrenSuppliers()
                                : activeUser.getParentSuppliers()
                          }
                          customDisplay={(option) =>
                              `${option.supplierId} - ${option.name}`
                          }
                          initialValue={
                            values.vendor.childSupplierCode
                                ? {
                                  supplierId: values.vendor.childSupplierCode,
                                  name: values.vendor.childSupplierName,
                                }
                                : values.vendor.scope === 3 ? firstChild : firstParent
                          }
                          onChange={(value) =>
                              setValues({
                                ...values,
                                vendor: {
                                  ...values.vendor,
                                  ...({
                                    childSupplierCode: value?.supplierId,
                                    childSupplierName: value?.name,
                                  }),
                                },
                              })
                          }
                      />
                    </Grid>
                    {!!childrenVendor &&
                        !forceChildCode &&
                        !loadingFullUserScope && (
                            <>
                              <Grid item xs={12} sm={4} style={{ marginBottom: 10 }}>
                                <span>Children Vendor:</span>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <ChildrenVendorComp
                                    userFullScope={userFullScope}
                                    zonesFromPsl={values.vendor.zones}
                                    marketsFromPsl={values.vendor.markets}
                                    companyCodesFromPsl={values.vendor.companyCodes}
                                    childrenVendor={childrenVendor}
                                    setAbsentCompCodesAndAbsentMarkets={
                                      setAbsentCompCodesAndAbsentMarkets
                                    }
                                />
                              </Grid>
                            </>
                        )}
                  </>
              ) : isParentPsl ? (
                  <>
                    <Grid item xs={12} sm={4}>
                      <CustomFastSelect
                          isMulti={false}
                          clearable={false}
                          hasError={!!getFieldMeta("vendor.scope").error}
                          disabled={isLocationEmpty || !!values.vendor.pslId}
                          initialValue={
                            values.vendor.scope === 3
                                ? { value: 3, label: "Local Vendor" }
                                : { value: 1, label: "Parent Vendor" }
                          }
                          options={[
                            { value: 1, label: "Parent Vendor" },
                            { value: 3, label: "Local Vendor" },
                          ]}
                          onChange={(value) => {
                            setFieldValue("vendor.scope", value?.value);
                            setFieldValue("vendor.childSupplierCode", null);
                            setFieldValue("vendor.childSupplierName", null);
                          }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <CustomFastAsyncSelect
                          onLoad={(searchTerm) => {
                            if (values.vendor.scope !== 3) {
                              return PromoItemsService.getPslAmdrSuppliersHierarchy(
                                  {
                                    searchTerm,
                                  }
                              ).then((results) => {
                                results.push({
                                  id: SUPPLIER_NOT_DEFINED_CODE,
                                  name: "Supplier Not Defined",
                                });
                                return results;
                              });
                            }
                            return PromoItemsService.getPslAmdrSuppliers({
                              searchTerm,
                              locationCompanyCodes: locationCompanyCodes,
                              isCreatePSL: values.vendor.pslId === null,
                            }).then((results) => {
                              results.push({
                                id: SUPPLIER_NOT_DEFINED_CODE,
                                name: "Supplier Not Defined",
                              });
                              return results;
                            });
                          }}
                          onLoadLimit={3}
                          disabled={isLocationEmpty || !!values.vendor.pslId}
                          hasError={
                            !!getFieldMeta("vendor.childSupplierCode").error
                          }
                          customDisplay={(option) =>
                              `${option.id} - ${option.name}`
                          }
                          initialValue={
                            values.vendor.childSupplierCode && !isLocationEmpty
                                ? {
                                  id: values.vendor.childSupplierCode,
                                  name: values.vendor.childSupplierName,
                                }
                                : null
                          }
                          onChange={(value) =>
                              setValues({
                                ...values,
                                vendor: {
                                  ...values.vendor,
                                  ...(value?.id === SUPPLIER_NOT_DEFINED_CODE
                                      ? {
                                        childSupplierCode: value?.id,
                                        childSupplierName: value?.name,
                                        statusId: 4,
                                      }
                                      : {
                                        childSupplierCode: value?.id,
                                        childSupplierName: value?.name,
                                      }),
                                },
                              })
                          }
                      />
                    </Grid>
                    {!!childrenVendor &&
                        !forceChildCode &&
                        !loadingFullUserScope && (
                            <>
                              <Grid item xs={12} sm={4} style={{ marginBottom: 10 }}>
                                <span>Children Vendor:</span>
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <ChildrenVendorComp
                                    userFullScope={userFullScope}
                                    zonesFromPsl={values.vendor.zones}
                                    marketsFromPsl={values.vendor.markets}
                                    companyCodesFromPsl={values.vendor.companyCodes}
                                    childrenVendor={childrenVendor}
                                    setAbsentCompCodesAndAbsentMarkets={
                                      setAbsentCompCodesAndAbsentMarkets
                                    }
                                />
                              </Grid>
                            </>
                        )}
                  </>
              ) : (
                <>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>
                      {
                        //editableByLocalizer ||
                        pslClass.isChildPsl() ? "Child " : "Local "
                      }
                      Vendor*:
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <CustomFastAsyncSelect
                      onLoad={(searchTerm) => {
                        return PromoItemsService.getPslAmdrSuppliers({
                          searchTerm,
                          locationCompanyCodes: locationCompanyCodes,
                          isCreatePSL: values.vendor.pslId === null,
                        }).then((results) => {
                          results.push({
                            id: SUPPLIER_NOT_DEFINED_CODE,
                            name: "Supplier Not Defined",
                          });
                          return results;
                        });
                      }}
                      onLoadLimit={3}
                      disabled={isLocationEmpty || !!values.vendor.pslId}
                      hasError={
                        !!getFieldMeta("vendor.childSupplierCode").error
                      }
                      customDisplay={(option) =>
                        `${option.id} - ${option.name}`
                      }
                      initialValue={
                        values.vendor.childSupplierCode && !isLocationEmpty
                          ? {
                              id: values.vendor.childSupplierCode,
                              name: values.vendor.childSupplierName,
                            }
                          : null
                      }
                      onChange={(value) => {
                        setValues({
                          ...values,
                          vendor: {
                            ...values.vendor,
                            ...(value?.id === SUPPLIER_NOT_DEFINED_CODE
                              ? {
                                  childSupplierCode: value?.id,
                                  childSupplierName: value?.name,
                                  statusId: 4,
                                }
                              : {
                                  childSupplierCode: value?.id,
                                  childSupplierName: value?.name,
                                }),
                          },
                        });
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={4}>
                <FormLabel>Supplier Status*:</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <CustomFastSelect
                  options={[
                    SelectBlank,
                    SelectMandatory,
                    SelectSoleSource,
                    SelectValidated,
                  ]}
                  disabled={
                    parseInt(values.vendor.childSupplierCode) === SUPPLIER_NOT_DEFINED_CODE || isSupplier
                  }
                  hasError={!!getFieldMeta("vendor.statusId").error}
                  initialValue={
                    {
                      [SupplierStatus.MANDATORY]: SelectMandatory,
                      [SupplierStatus.SOLE_SOURCE]: SelectSoleSource,
                      [SupplierStatus.VALIDATED]: SelectValidated,
                    }[values.vendor.statusId] || SelectBlank
                  }
                  onChange={(value) =>
                    setFieldValue("vendor.statusId", value?.value)
                  }
                />
              </Grid>
              {values.vendor.statusId === SupplierStatus.MANDATORY ||
              values.vendor.statusId === SupplierStatus.SOLE_SOURCE ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>Justification*:</FormLabel>
                  </Grid>
                  <Grid item sm={8}>
                    <TextFieldWrapper
                      multiline
                      minRows={3}
                      name="vendor.statusJustification"
                      fullWidth
                      size="small"
                      error={!!getFieldMeta("vendor.statusJustification").error}
                      helperText={
                        "For compliance reasons, in case you selected “Mandatory” or “Sole Source” status, please justify the rationale and/or attach an evidence such as your sourcing summary or justification signed by your manager (you can attach more than one documents if necessary)"
                      }
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Grid item xs={12} sm={4}>
                <FormLabel>Sup. Contact Name:</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextFieldWrapper
                  name="vendor.pslVendorContactName"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormLabel>Supplier Website:</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextFieldWrapper
                  name="vendor.pslVendorContactUrl"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormLabel>Sup. Email Address:</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextFieldWrapper
                  name="vendor.pslVendorContactEmail"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormLabel>Sup. Phone Number:</FormLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextFieldWrapper
                  name="vendor.pslVendorContactPhone"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
              Scope Definition
            </Typography>
            <Grid container spacing={2} alignItems={"flex-start"}>
              <Grid item xs={12} sm={4}>
                Location*:
              </Grid>
              <Grid item xs={12} sm={8}>
                {isParentPsl ? (
                  <UserFullScopeComponent
                      disableZones={isSupplier}
                    hasError={
                      !!getFieldMeta("vendor.zones").error ||
                      !!getFieldMeta("vendor.markets").error ||
                      !!getFieldMeta("vendor.companyCodes").error
                    }
                    initialMarkets={values.vendor.markets}
                    initialCompanies={values.vendor.companyCodes}
                    initialZones={values.vendor.zones}
                    onChange={(newValues) => {
                      const withLocation =
                        newValues.zones?.length > 0 ||
                        newValues.markets?.length > 0 ||
                        newValues.companyCodes?.length > 0;
                      setValues({
                        ...values,
                        vendor: {
                          ...values.vendor,
                          zones:
                            newValues.zones?.map((el) => ({
                              id: el.id,
                              name: el.title,
                            })) || [],
                          markets:
                            newValues.markets?.map((el) => ({
                              id: el.id,
                              name: el.title,
                            })) || [],
                          companyCodes:
                            newValues.companyCodes?.map((el) => ({
                              id: el.id,
                              name: el.title,
                              code: el.code,
                            })) || [],
                          childSupplierCode: withLocation
                            ? values.vendor.childSupplierCode
                            : null,
                          childSupplierName: withLocation
                            ? values.vendor.childSupplierName
                            : null,
                        },
                      });
                      if (!withLocation) setChildrenVendor(null);
                    }}
                    disabled={false}
                  />
                ) : (
                  <UserScopeComponent
                    hasError={
                      !!getFieldMeta("vendor.zones").error ||
                      !!getFieldMeta("vendor.markets").error ||
                      !!getFieldMeta("vendor.companyCodes").error
                    }
                    initialMarkets={values.vendor.markets}
                    initialCompanies={values.vendor.companyCodes}
                    onChange={(newValues) => {
                      const withLocation =
                        newValues.zones?.length > 0 ||
                        newValues.markets?.length > 0 ||
                        newValues.companyCodes?.length > 0;
                      setValues({
                        ...values,
                        vendor: {
                          ...values.vendor,
                          zones:
                            newValues.zones?.map((el) => ({
                              id: el.id,
                              name: el.title,
                            })) || [],
                          markets:
                            newValues.markets?.map((el) => ({
                              id: el.id,
                              name: el.title,
                            })) || [],
                          companyCodes:
                            newValues.companyCodes?.map((el) => ({
                              id: el.id,
                              name: el.title,
                              code: el.code,
                            })) || [],
                          childSupplierCode: withLocation
                            ? values.vendor.childSupplierCode
                            : null,
                          childSupplierName: withLocation
                            ? values.vendor.childSupplierName
                            : null,
                        },
                      });
                      if (!withLocation) setChildrenVendor(null);
                    }}
                    disabled={false}
                    limitedLocation={false}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                Business Unit:
              </Grid>
              <Grid item xs={12} sm={8}>
                <CustomFastSelect
                  isMulti={true}
                  // disabled={readOnly}
                  initialValue={null}
                  options={businessUnits.map((v) => ({
                    value: v.id,
                    label: v.name,
                  }))}
                  onChange={(value) => {
                    setFieldValue(
                      "vendor.businessUnits",
                      value
                        ? value.map((v) => ({
                            id: v.value,
                            name: v.label,
                          }))
                        : null
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                Yearly Spend Estimation:
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Select
                      size="small"
                      fullWidth
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={values.vendor.yearlySpendCurrencyCode}
                      label="Age"
                      onChange={(e) =>
                        setFieldValue(
                          "vendor.yearlySpendCurrencyCode",
                          e.target.value
                        )
                      }
                    >
                      {currencies.map((currency) => {
                        return (
                          <MenuItem value={currency.CurrencyKey}>
                            {currency.Description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                Agreements:
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextFieldWrapper
                  name="vendor.agreements"
                  fullWidth
                  size="small"
                />
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                Price list link:
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextFieldWrapper
                  inputProps={{
                    type: "url",
                    pattern:
                      "(https://www.|http://www.|https://|http://)?[a-zA-Z]{2,}(.[a-zA-Z]{2,})(.[a-zA-Z]{2,})?/[a-zA-Z0-9]{2,}|((https://www.|http://www.|https://|http://)?[a-zA-Z]{2,}(.[a-zA-Z]{2,})(.[a-zA-Z]{2,})?)|(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}.[a-zA-Z0-9]{2,}.[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})?",
                    placeholder: "https://www.example.com",
                  }}
                  name="vendor.priceList"
                  fullWidth
                  size="small"
                />
              </Grid> */}
              <Grid item xs={12} sm={4}>
                Tender Number:
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextFieldWrapper
                  name="vendor.tenderNumber"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                Valid From:
              </Grid>
              <Grid item xs={12} sm={8}>
                <DatePickerWrapper
                  name="vendor.validFrom"
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                Valid To*:
              </Grid>
              <Grid item xs={12} sm={8}>
                <DatePickerWrapper
                  name="vendor.validTo"
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      error: !!getFieldMeta("vendor.validTo").error,
                      helperText: getFieldMeta("vendor.validTo").error,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                Note to Requester:
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextFieldWrapper
                  name="vendor.noteRequester"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                Note to Local Procurement:
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextFieldWrapper
                  name="vendor.noteLocalProcurement"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                hidden={
                  !values.pslId ||
                  [PSL_ABOVE_MARKET, PSL_PPA_ABOVE_MARKET, 3].indexOf(
                    values.vendor.scope
                  ) > -1
                }
              >
                EasyBuy:
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                hidden={
                  !values.pslId ||
                  [PSL_ABOVE_MARKET, PSL_PPA_ABOVE_MARKET, 3].indexOf(
                    values.vendor.scope
                  ) > -1
                }
              >
                <Tooltip
                  placement={"right"}
                  title={
                    "Tick the box in case you want the PSL form to be pushed to EasyBuy as catalogs"
                  }
                >
                  <CheckboxWrapper
                    name="vendor.relatedToEasyBuy"
                    label="Should be managed in EasyBuy"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CreateStepOneContainer;
