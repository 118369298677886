import {
  createQueryService,
  createFormDataService,
  createMutationService,
} from "utils/factories";
import {
  ActivityLogDTO,
  CampaignMaterialType,
  CampaignShortDTO,
  CreateCampaignDTO,
} from "./campaigns.types";
import { PaginatedQueryResult } from "service/generic.types";
import { PromoItemRowType } from "modules/PromoItems/services/promoItems.types";

const moduleBasePath = "/api/order-windows";
export default class CampaignsService {
  static readonly getCampaigns = createMutationService<
    PaginatedQueryResult<CampaignShortDTO>,
    any
  >(
    ({ take, skip }) =>
      `${moduleBasePath}/get-filtered?take=${take || 10}&skip=${skip || 0}`
  );

  static readonly getCampaign = createQueryService<
    CampaignShortDTO,
    { id: string | number }
  >(({ id }) => `${moduleBasePath}/${id}`);

  static readonly createCampaign = createMutationService<
    CampaignShortDTO,
    CreateCampaignDTO
  >(`${moduleBasePath}/create`);

  static readonly updateCampaign = createMutationService<
    CampaignShortDTO,
    CreateCampaignDTO
  >(`${moduleBasePath}/update`, "PUT");

  static readonly publishCampaign = createMutationService<
    any,
    { campaignId: string | number }
  >(({ campaignId }) => `${moduleBasePath}/publish/${campaignId}`, "POST");

  static readonly archiveCampaign = createMutationService<
    any,
    { campaignId: string | number }
  >(({ campaignId }) => `${moduleBasePath}/delete?id=${campaignId}`, "DELETE");

  static readonly deleteCampaignMedia = createMutationService<
    any,
    { fileId: string }
  >(({ fileId }) => `${moduleBasePath}/files/${fileId}`, "DELETE");

  static readonly uploadCampaignMediaFromLink = createMutationService<
    any,
    { link: string; campaignId?: string }
  >(
    ({ link, campaignId }) =>
      `${moduleBasePath}/files/upload-file-from-link?link=${link}${
        campaignId ? `&orderWindowId=${campaignId}` : ""
      }`,
    "POST"
  );

  static readonly uploadCampaignMedia = createFormDataService<any, any>(
    ({ campaignId }) =>
      `${moduleBasePath}/files/upload-file?${
        campaignId ? `orderWindowId=${campaignId}` : ""
      }`,
    "POST"
  );

  static readonly getCampaignSubscribers = createQueryService<
    { name: string; email: string; campaignId: number }[],
    { campaignId: string }
  >(({ campaignId }) => `${moduleBasePath}/${campaignId}/subscribers`);

  static readonly subcribeMeToCampaign = createMutationService<
    never,
    { campaignId }
  >(
    ({ campaignId }) => `${moduleBasePath}/${campaignId}/subscribers/add/me`,
    "POST"
  );

  static readonly subcribeOthersToCampaign = createMutationService<
    never,
    {
      campaignId: string;
      subscribers: { name: string; email: string; orderWindowId: number }[];
    }
  >(
    ({ campaignId }) =>
      `${moduleBasePath}/${campaignId}/subscribers/add/others`,
    "POST"
  );

  static readonly unsubscribeMemberFromCampaign = createMutationService<
    never,
    { campaignId; email: string }
  >(
    ({ campaignId, email }) =>
      `${moduleBasePath}/${campaignId}/subscribers/${email}`,
    "DELETE"
  );

  static readonly getCampaignActivityLog = createQueryService<
    ActivityLogDTO[],
    { campaignId: string | number }
  >(({ campaignId }) => `${moduleBasePath}/${campaignId}/logs`);

  static readonly createCampaignMaterial = createMutationService<
    any,
    CampaignMaterialType
  >("/api/order-window-materials/create", "POST");

  static readonly getCampaignMaterials = createQueryService<
    { data: CampaignMaterialType[]; total: number; take: number; skip: number },
    { orderWindowId: number; take: number; skip: number }
  >(
    ({ orderWindowId }) =>
      `/api/order-window-materials/get-materials-by-order-window`
  );

  static readonly updateCampaignMaterial = createMutationService<
    any,
    CampaignMaterialType
  >("/api/order-window-materials/update", "PUT");

  static readonly duplicateCampaignMaterial = createMutationService<
    any,
    { materialId : number | string}
  >("/api/order-window-materials/duplicate", "POST");

  static readonly deleteCampaignMaterial = createMutationService<
    any,
    { id: number }
  >(({ id }) => `/api/order-window-materials/delete?id=${id}`, "DELETE");

  static readonly deleteCampaignMaterialMedia = createMutationService<
    any,
    { fileId: string }
  >(({ fileId }) => `/api/order-window-materials/files/${fileId}`, "DELETE");

  static readonly uploadCampaignMaterialMediaFromLink = createMutationService<
    any,
    { link: string; campaignMaterialId?: string }
  >(
    ({ link, campaignMaterialId }) =>
      `/api/order-window-materials/files/upload-file-from-link?link=${link}${
        campaignMaterialId ? `&orderWindowMaterialId=${campaignMaterialId}` : ""
      }`,
    "POST"
  );

  static readonly uploadCampaignMaterialMedia = createFormDataService<
    any,
    {
      campaignMaterialId: string | number;
      file: File;
    }
  >(
    ({ campaignMaterialId }) =>
      `/api/order-window-materials/files/upload-file?${
        campaignMaterialId ? `orderWindowMaterialId=${campaignMaterialId}` : ""
      }`,
    "POST"
  );
}
