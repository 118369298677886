import { useQuery } from "@tanstack/react-query";
import { MaterialReactTable } from "material-react-table";
import React from "react";
import CampaignsService from "../service/campaigns.service";
import moment from "moment";

export const CampaignsMaintenanceContainer = () => {
  const {
    data: allCampaigns,
    refetch: refetchAllCampaigns,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["campaigns/all"],
    queryFn: async () => {
      const response = await CampaignsService.getCampaigns({
        searchTerm: "",
        skip: 0,
        take: 100,
      });
      return response;
    },
    // initialData: { data: [], take: 100, skip: 0, total: 0 },
  });
  return (
    <MaterialReactTable
      state={{
        isLoading,
        showProgressBars: isRefetching,
      }}
      initialState={{
        pagination: {
          pageIndex: 0,
          pageSize: 100,
        },
      }}
      mrtTheme={{
        baseBackgroundColor: "#fff",
      }}
      columns={[
        {
          accessorKey: "id",
          header: "ID",
        },
        {
          accessorKey: "name",
          header: "Name",
        },
        {
          accessorKey: "description",
          header: "About",
        },
        {
          accessorKey: "state",
          header: "State",
          Cell: ({ row }) => {
            return (
              <>{["Draft", "Active", "Future", "Past", "Archived"][row.original.state]}</>
            );
          },
        },
        {
          accessorKey: "startDate",
          header: "Start Date",
          Cell: ({ row }) => {
            return (
              <>
                {moment(row.original.startDate).format("DD/MM/YYYY")}
                <br />({moment(row.original.startDate).fromNow()}){" "}
              </>
            );
          },
        },
        {
          accessorKey: "endDate",
          header: "End Date",
          Cell: ({ row }) => {
            return (
              <>
                {moment(row.original.endDate).format("DD/MM/YYYY")}
                <br />({moment(row.original.endDate).fromNow()}){" "}
              </>
            );
          },
        },
        {
          accessorKey: "materialsCount",
          header: "Materials Count",
        },
        {
          accessorKey: "scope",
          header: "Scope",
        },
        {
          accessorKey: "supplierName",
          header: "Supplier",
        },
        {
          accessorKey: "supplierContact.email",
          header: "Supplier Contact",
        },
      ]}
      data={allCampaigns?.data || []}
      renderDetailPanel={({ row }) => (
        <CampaignMaterialsListContainer campaignId={row.original.id} />
      )}
    />
  );
};

const CampaignMaterialsListContainer = (props: {
  campaignId: string | number;
}) => {
  const {
    data: { data: materials },
    refetch: refetchMaterials,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["materials", props.campaignId],
    queryFn: async () => {
      const response = await CampaignsService.getCampaignMaterials({
        orderWindowId: parseInt(props.campaignId.toString()),
        take: 100,
        skip: 0,
      });

      // setMaterialQuantities(
      //   response.data.reduce((acc, material) => {
      //     acc[material.id] = material.minimumOrderQuantity;
      //     return acc;
      //   }, {})
      // );

      return response;
    },

    initialData: {
      data: [],
      take: 100,
      skip: 0,
      total: 0,
    },
  });
  return (
    <MaterialReactTable
      state={{
        isLoading,
        showProgressBars: isRefetching,
      }}
      initialState={{
        isLoading,
        pagination: {
          pageIndex: 0,
          pageSize: 100,
        },
      }}
      mrtTheme={{
        baseBackgroundColor: "#fff",
      }}
      columns={[
        {
          accessorKey: "id",
          header: "ID",
        },
        {
          accessorKey: "promoCategoryId",
          header: "Promo Category",
        },
        {
          accessorKey: "materialName",
          header: "Material Name",
        },
        {
          accessorKey: "shortDescription",
          header: "Short Description",
        },
        {
          accessorKey: "itemDescription",
          header: "Scope of Application",
        },
        {
          accessorKey: "supplierPartId",
          header: "Supplier Part ID",
        },
        {
          accessorKey: "manufacturerName",
          header: "Manufacturer Name",
        },
        {
          accessorKey: "manufacturerPartNumber",
          header: "Manufacturer Part Number",
        },
        {
          accessorKey: "supplierLeadTime",
          header: "Supplier Lead Time",
        },
        {
          accessorKey: "incoterms",
          header: "Incoterms",
        },
        {
          accessorKey: "currencyCode",
          header: "Currency",
        },
        {
          accessorKey: "unitOfMeasure",
          header: "Unit Of Measure",
        },
        {
          accessorKey: "minimumOrderQuantity",
          header: "Minimum Order Quantity",
        },
        {
          accessorKey: "packQuantity",
          header: "Package Quantity",
        },
        {
          accessorKey: "packUnit",
          header: "Package Unit",
        },
        {
          accessorKey: "purchQuantity",
          header: "Minimum Purchase Quantity",
        },
        {
          accessorKey: "keywords",
          header: "Keywords",
        },
        {
          accessorKey: "languageId",
          header: "Language",
        },
      ]}
      data={materials || []}
    />
  );
};

export default CampaignsMaintenanceContainer;
